/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ pageDescription, lang, meta, pageTitle, pagePath }) {
    const {
        site: {
            siteMetadata: { description, title, image, author, url }
        }
    } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author {
                            name
                        }
                        image
                        siteUrl
                    }
                }
            }
        `
    );

    return (
        <Helmet title={`${title} | ${pageTitle}`}>
            <meta name="description" content={pageDescription || description} />

            <meta property="og:title" content={`${title} | ${pageTitle}`} />
            <meta property="og:description" content={pageDescription || description} />
            {/* <meta property="og:image" content={image} /> */}
            <meta property="og:url" content={`${url}${pagePath}`} />
            <meta property="og:type" content="website" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content={author} />
            <meta name="twitter:title" content={`${title} | ${pageTitle}`} />
            <meta name="twitter:description" content={pageDescription || description} />
        </Helmet>
    );
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    pageDescription: ``,
    pageTitle: ``,
    pagePath: ``
};

SEO.propTypes = {
    pageDescription: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    pageTitle: PropTypes.string,
    pagePath: PropTypes.string
};

export default SEO;
